import React, { createContext, useContext, useState } from 'react';

const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [chatData, setChatData] = useState({
    charactersPrompt: [],
    Baseprompts: [],
    level: [],
    conversation: [],
    // useExpressionPrompt: [],
    charactersNum:0,
    charactersName:[],
    maxCharacterRelationNum:0,
  });

  // Function to update charactersPrompt
  const updateCharactersPrompt = (newData) => {
    setChatData((prevData) => ({
      ...prevData,
      charactersPrompt: newData,
    }));
  };

  // Function to update Baseprompts
  const updateBaseprompts = (newData) => {
    setChatData((prevData) => ({
      ...prevData,
      Baseprompts: newData,
    }));
  };

  // Function to update level
  const updateLevel = (newData) => {
    setChatData((prevData) => ({
      ...prevData,
      level: [{role: "system", content: "Use CEFR Level " + newData + " English from now on."}],
    }));
  };

  // Function to update conversation
  const updateConversation = (newData) => {
    console.log('Updating conversation with:', newData);
    setChatData((prevData) => ({
      ...prevData,
      conversation: newData,
    }));
  };

  // Function to update useExpressionPrompt
  const updateUseExpressionPrompt = (newData) => {
    setChatData((prevData) => ({
      ...prevData,
      useExpressionPrompt: newData,
    }));
  };

  // Function to update characterNum
  const updateCharacterNum = (newData) => {
    setChatData((prevData) => ({
      ...prevData,
      charactersNum: newData,
    }));
  };

   // Function to update characterName
   const updateCharactersName = (newData) => {
    setChatData((prevData) => ({
      ...prevData,
      charactersName: newData,
    }));
  };

  // Function to update maxCharacterRelationNum
  const updateMaxCharactersNum = (newData) => {
    setChatData((prevData) => ({
      ...prevData,
      maxCharacterRelationNum: newData,
    }));
  };  

  return (
    <ChatContext.Provider
      value={{
        chatData,
        updateCharactersPrompt,
        updateBaseprompts,
        updateLevel,
        updateConversation,
        updateUseExpressionPrompt,
        updateCharacterNum,
        updateCharactersName,
        updateMaxCharactersNum,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChatContext = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error('useChatContext must be used within a ChatProvider');
  }
  return context;
};
