import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import NotFound from "pages/NotFound";
import { useAuth } from "context/AuthContext";
import { useNavigationContext } from "context/NavigationContext";

const Chatroom = React.lazy(() => import("pages/Chatroom"));
const SetupaChatroom = React.lazy(() => import("pages/SetupaChatroom"));
const LandingPageSignedIn = React.lazy(() => import("pages/LandingPageSignedIn"));
const LandingPageSignedout = React.lazy(() => import("pages/LandingPageSignedout"));

const ProtectedRoute = ({ children, stepRequired }) => {
  const { currentUser } = useAuth();
  const { step } = useNavigationContext();

  if (!currentUser) {
    return <Navigate to="/landingpagesignedout" replace />;
  }

  if (step < stepRequired) {
    // Redirect to the first allowed page based on the step
    const redirectTo = step === 1 ? "/landingpagesignedin" : "/setupachatroom";
    return <Navigate to={redirectTo} replace />;
  }

  return children;
};

const ProjectRoutes = () => {
  return (
    <React.Suspense fallback={<>Loading...</>}>
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/landingpagesignedout" replace />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/landingpagesignedout" element={<LandingPageSignedout />} />
          
          {/* Protected routes */}
          <Route
            path="/landingpagesignedin"
            element={<ProtectedRoute stepRequired={1}><LandingPageSignedIn /></ProtectedRoute>}
          />
          <Route
            path="/setupachatroom/:category/:chatroomName"
            element={<ProtectedRoute stepRequired={2}><SetupaChatroom /></ProtectedRoute>}
          />
          <Route
            path="/chatroom"
            element={<ProtectedRoute stepRequired={3}><Chatroom /></ProtectedRoute>}
          />
        </Routes>
      </Router>
    </React.Suspense>
  );
};

export default ProjectRoutes;
