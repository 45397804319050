// NavigationContext.js
import React, { createContext, useContext, useState } from 'react';

const NavigationContext = createContext();

export const useNavigationContext = () => useContext(NavigationContext);

export const NavigationProvider = ({ children }) => {
  const [step, setStep] = useState(1); // 1 for landingpagesignedin, 2 for setupachatroom, 3 for chatroom
  
  const advanceStep = () => {
    setStep(prev => prev + 1);
  };

  const resetStep = () => {
    setStep(1);
  };

  return (
    <NavigationContext.Provider value={{ step, advanceStep, resetStep }}>
      {children}
    </NavigationContext.Provider>
  );
};
