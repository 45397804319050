import React, { createContext, useContext, useState } from "react";

const ChatroomContext = createContext();

export function useChatroom() {
  return useContext(ChatroomContext);
}

export function ChatroomProvider({ children }) {
  const [category, setCategory] = useState("");
  const [chatroomName, setChatroomName] = useState("");
  const [BG, setBG] = useState("");


  return (
    <ChatroomContext.Provider value={{ category, setCategory, chatroomName, setChatroomName,  BG, setBG }}>
      {children}
    </ChatroomContext.Provider>
  );
}
