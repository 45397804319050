// File: SelectedCharactersContext.js
import React, { createContext, useContext, useState } from 'react';

const SelectedCharactersContext = createContext();

export const SelectedCharactersProvider = ({ children }) => {
  const [selectedCharacters, setCharacters] = useState([]);

  const setSelectedCharacters = (characters) => {
    setCharacters(characters);
  };

  return (
    <SelectedCharactersContext.Provider value={{ selectedCharacters, setSelectedCharacters }}>
      {children}
    </SelectedCharactersContext.Provider>
  );
};

export const useSelectedCharacters = () => {
  return useContext(SelectedCharactersContext);
};
