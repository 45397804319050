import { ChatroomProvider } from "context/ChatroomContext";
import { ChatProvider } from "context/ChatContext";  // Import the ChatProvider
import React from "react";
import Routes from "./Routes";
import { AuthProvider } from "context/AuthContext";
import { ProSidebarProvider } from "react-pro-sidebar";
import { NavigationProvider } from "context/NavigationContext";
import { SelectedCharactersProvider } from "context/SelectedCharacters";

function App() {
  return (
    <AuthProvider>
      <NavigationProvider>
        <ProSidebarProvider>
          <SelectedCharactersProvider>
            <ChatroomProvider>
              <ChatProvider>  
                <Routes />
              </ChatProvider>
            </ChatroomProvider>
          </SelectedCharactersProvider>
        </ProSidebarProvider>
      </NavigationProvider>
    </AuthProvider>
  );
}

export default App;
